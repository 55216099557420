<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Traslado </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <SelectTipoTranslado
                  class="mb-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_tipo_translado"
                  :rules="reglas.requerido"
                />
                <SelectAlmacen
                  class="mb-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_almacen_origen"
                  :filtro="{ id_puesto: store.state.puestoSelect.id_puesto }"
                  :label="`Almacen Origen`"
                  :rules="reglas.requerido"
                />
                <SelectPuesto
                  :rules="datos.id_tipo_translado == 2 ? reglas.id_puesto_destino : []"
                  :label="`Area Destino`"
                  class="mb-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_puesto_destino"
                  v-show="datos.id_tipo_translado == 2"
                  :ind_principal="true"
                />
                <SelectAlmacen
                  class="mb-4"
                  :rules="reglas.id_almacen_destino"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_almacen_destino"
                  :filtro="{
                    id_puesto:
                      datos.id_tipo_translado == 2 ? datos.id_puesto_destino : store.state.puestoSelect.id_puesto,
                  }"
                  :label="`Almacen Destino`"
                />

                <v-switch v-if="validarAcciones(acciones.TRASLADO_TIPO_GASTO) " v-model="datos.ind_gasto" :label="`Tipo Gasto`"></v-switch>
              </v-col>
              <v-col cols="12" lg="6">
                <DatePicker
                  :rules="reglas.requerido"
                  v-model="datos.fecha_translado"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha Traslado'"
                />
                <v-textarea
                  :rules="reglas.requerido"
                  v-model="datos.descripcion"
                  class="mt-0"
                  label="Descripcion"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row class="mb-4 mt-2">
          <v-col cols="12">
            <BuscarProducto
              :disabled="!(datos.id_almacen_origen > 0)"
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{
                id_puesto: store.state.puestoSelect.id_puesto,
                ind_sin_recetas_produccion: true,
              }"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-left">Costo</th>
              <th class="text-left">Existencia</th>
              <th class="text-left">Cantidad</th>
              <th v-if="datos.id_tipo_translado == 2">Almacen</th>
              <th class="text-left" v-if="datos.id_tipo_translado == 1">Transformar</th>
              <th class="text-left" v-if="datos.id_tipo_translado == 1"><small>Producto Transformar</small></th>
              <th class="text-left" v-if="datos.id_tipo_translado == 1"><small>Cantidad Transformar</small></th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small>{{ item.producto.unidad_medida.descripcion }}</small>
              </td>
              <td>$.{{ funciones.formatoNumeric(item.producto.costo) }}</td>
              <td>{{ funciones.formatoNumeric(item.existencia) }}</td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.cantidad"
                  dense
                  outlined
                  @keyup="changeProductoTransformar(item)"
                ></v-text-field>
                <small class="error--text" v-if="isNaN(item.cantidad)"> debe ser un numero valido </small>
                <small class="error--text" v-else-if="item.cantidad <= 0"> debe ser un nuemero positivo </small>
                <small class="error--text" v-else-if="item.cantidad > item.existencia">
                  la cantidad excede la existencia
                </small>
              </td>
              <td v-if="datos.id_tipo_translado == 2">
                <v-select
                  class="mt-1"
                  hide-details=""
                  style="width: 200px"
                  :items="item.producto.existencias"
                  item-text="almacen.descripcion"
                  item-value="almacen.id"
                  label="Almacen"
                  outlined
                  dense
                  v-model="item.id_almacen"
                  @change="cambiarAlmacenProducto(item)"
                ></v-select>
              </td>
              <td v-if="datos.id_tipo_translado == 1">
                <v-checkbox
                  v-show="item.producto.productos_ing.length > 0"
                  @change="changeTipoTransferenciaItem(item)"
                  v-model="item.ind_conversion"
                ></v-checkbox>
              </td>
              <td v-if="datos.id_tipo_translado == 1">
                <v-select
                  class="mt-1"
                  hide-details=""
                  style="width: 200px"
                  v-if="item.ind_conversion"
                  :items="item.producto.productos_ing"
                  item-text="producto.nombre"
                  item-value="producto.id"
                  label="Producto"
                  outlined
                  dense
                  v-model="item.id_producto_conversion"
                  @change="changeProductoTransformar(item)"
                ></v-select>
              </td>
              <td v-if="datos.id_tipo_translado == 1">
                <span v-if="item.ind_conversion">
                  <small v-if="item.productos_ing_select"
                    >Cantidad: {{ item.unidad_conversion }}
                    {{ item.productos_ing_select.producto.unidad_medida.unidad }}
                  </small>
                  <br />
                  <small v-if="item.productos_ing_select"
                    >Total: {{ item.cantidad_conversion }}
                    {{ item.productos_ing_select.producto.unidad_medida.unidad }}</small
                  >
                </span>
              </td>
              <td>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <small class="error--text" v-if="datos.productos.length == 0">* Debe agregar por lo menos un producto </small>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil } from '@mdi/js'
import TransladoServices from '@/api/servicios/inventario/TransladoServices'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue'
import SelectTipoTranslado from './SelectTipoTranslado.vue'
import SolicitudServices from '@/api/servicios/inventario/SolicitudServices'
import ProductosService from '@/api/servicios/ProductosService'
import { acciones } from '@/modulos'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.id_almacen_origen': function (news, olds) {
      this.cambiarAlmacen(news, olds)
    },
    'datos.id_tipo_translado': function () {
      this.changeTipoTransferencia()
    },
  },
  components: {
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    SelectTipoTranslado,
    SelectPuesto,
  },
  mounted() {
    if (this.$route.params.id_solicitud) {
      if (this.$route.params.id_solicitud > 0) {
        this.cargarSolcitud(this.$route.params.id_solicitud)
      }
    }
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)
    const route = ref(null)
    const RefProductoDetalle = ref(null)

    const consultarProducto = async (id, cantidad) => {
      await ProductosService.productoExistenciaListarBusqueda({
        id,
        id_puesto: store.state.puestoSelect.id_puesto,
        ind_sin_recetas_produccion: true,
        valor: '',
      })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            response.data.datos
            if (response.data.datos != null && response.data.datos.length > 0) {
              const item = response.data.datos[0]
              itemAdd.value = {
                id: item.id,
                cantidad: cantidad,
                existencia: '0',
                ind_conversion: false,
                id_producto_conversion: null,
                unidad_conversion: 0,
                cantidad_conversion: 0,
                costo_conversion: 0,
                id_almacen: datos.value.id_almacen_origen,
                producto: {
                  id: item.id,
                  nombre: item.nombre,
                  costo: item.costo,
                  existencias: item.existencias,
                  unidad_medida: item.unidad_medida,
                  productos_ing: item.productos_ing,
                },
              }
              agregarDetalle()
            }
          } else {
            store.commit('setAlert', {
              message: 'no se consiguió el producto' + id,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      // .finally( )
    }

    const cargarSolcitud = id => {
      goTo(0)
      SolicitudServices.solicitudDetalleListarPaginado({ id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            if (response.data.datos.datos != null && response.data.datos.datos.length > 0) {
              const datoin = response.data.datos.datos[0]
              if (datosInit.id_puesto_origen != store.state.puestoSelect.id_puesto) {
                store.commit('setAlert', {
                  message: 'Solicitud no pertenece a esta area',
                  type: 'info',
                })
              } else {
                if (datoin.estado == 67) {
                  datos.value.id_tipo_translado = 2
                  datos.value.id_puesto_origen = datoin.id_puesto_origen
                  datos.value.id_puesto_destino = datoin.id_puesto_destino
                  datos.value.id_almacen_destino = datoin.id_almacen_destino
                  datos.value.fecha_translado = datoin.fecha_solicitud
                  datos.value.descripcion = datoin.descripcion
                  datos.value.id_solicitud_translado = id
                  datoin.productos.forEach(async element => {
                    await consultarProducto(element.id_producto, element.cantidad)
                  })
                } else {
                  store.commit('setAlert', {
                    message: 'La solcitud ya fue procesada',
                    type: 'info',
                  })
                }
              }
            } else {
              store.commit('setAlert', {
                message: 'No se consiguio la solicitud',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Error al cargar la solicitud' + error,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'cargarSolcitud',
          })
        })
        .finally(() => {})
    }

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      id_puesto_destino: [
        v => !!v || v == 0 || 'Es requerido',
        v => v != datos.value.id_puesto_origen || 'No puede ser igual al origen',
      ],
      id_almacen_destino: [
        v => (!!v && v != 0) || 'Es requerido',
         
      ],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }
    const datosInit = {
      id: -1,
      id_puesto_origen: store.state.puestoSelect.id_puesto,
      id_almacen_origen: null,
      id_puesto_destino: null,
      id_almacen_destino: null,
      ind_gasto:false,
      estado: 57,
      fecha_translado: null,
      descripcion: null,
      id_tipo_translado: 1,
      id_solicitud_translado: null,
      productos: [],
    }
    const itemAddInit = {
      cantidad: '0',
      existencia: '0',
      producto: {},
      ind_conversion: false,
      id_producto_conversion: null,
      unidad_conversion: 0,
      cantidad_conversion: 0,
      costo_conversion: 0,
    }
    const filtrarAlmacenCosto = item => {
      var exis = {}
      exis = item.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)

      return exis
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto_origen: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
          id_puesto_destino: store.state.puestoSelect.id_puesto,
        }),
      ),
    )

    onBeforeMount(() => {})

    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto_origen: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
          id_puesto_destino: store.state.puestoSelect.id_puesto,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))

      form.value.resetValidation()
    }
    const changeTipoTransferenciaItem = element => {
      if (element.ind_conversion == false) {
        element.cantidad_conversion = null
        element.unidad_conversion = null
        element.productos_ing_select = null
        element.id_producto_conversion = null
      }
    }
    const changeTipoTransferencia = () => {
      if (datos.value.id_tipo_translado == 1) {
        datos.value.id_puesto_destino = datos.value.id_puesto_origen
        datos.value.productos.forEach(element => {
          element.ind_conversion = false
          element.cantidad_conversion = null
          element.unidad_conversion = null
          element.productos_ing_select = null
          element.id_producto_conversion = null
        })
      }
    }
    const changeProductoTransformar = item => {
      var ing = item.producto.productos_ing.find(it => it.producto.id == item.id_producto_conversion)
      item.unidad_conversion = ing.cantidad
      item.cantidad_conversion = ing.cantidad * item.cantidad
      item.productos_ing_select = ing
    }

    var editando = false

    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)
    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (editando == false) {
        if (item != undefined) {
          itemAdd.value = {
            id: item.id,
            cantidad: '0',
            existencia: '0',
            ind_conversion: false,
            id_producto_conversion: null,
            unidad_conversion: 0,
            cantidad_conversion: 0,
            costo_conversion: 0,
            id_almacen: datos.value.id_almacen_origen,
            producto: {
              id: item.id,
              nombre: item.nombre,
              costo: item.costo,
              existencias: item.existencias,
              unidad_medida: item.unidad_medida,
              productos_ing: item.productos_ing,
            },
          }
          agregarDetalle()
        }
      } else {
        editando = false
      }
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        fecha_translado: funciones.formatoFecha(item.fecha_translado, 4),
      }
      goTo(0)
      cambiarAlmacen()
    }

       
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const validarForm = () => {
      let val = form.value?.validate()
      if (datos.value.productos.length == 0) {
        val = false
      }
      datos.value.productos.forEach(item => {
        if (!(item.cantidad > 0 && item.cantidad <= item.existencia)) {
          val = false
        } else {
          if (datos.value.id_tipo_translado == 1 && item.ind_conversion == true && !(item.cantidad_conversion > 0)) {
            val = false
          }
        }
      })
      return val
    }

    const agregarDetalle = () => {
      if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
        let exis = itemAdd.value.producto.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)
        itemAdd.value.id_almacen = datos.value.id_almacen_origen
        if (exis) {
          itemAdd.value.existencia = Number(exis.existencia)
        } else {
          itemAdd.value.existencia = 0
        }

        datos.value.productos.push({ ...itemAdd.value })
        itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
        RefProductoDetalle.value.limpiar()
        // cambiarAlmacen()
      } else {
        store.commit('setAlert', {
          message: 'Ya existe el producto en la factura',
          type: 'warning',
        })
      }
    }

    const cambiarAlmacenProducto = element => {
      var exis = null
      exis = element.producto.existencias.find(it => it.id_almacen == element.id_almacen)

      if (exis) {
        console.log('exis', exis.existencia)
        element.existencia = Number(exis.existencia)
      } else {
        element.existencia = 0
      }
    }
    const cambiarAlmacen = (news, olds) => {
      datos.value.productos.forEach(element => {
        if (olds != null) {
          if (olds == element.id_almacen) {
            var exis = null
            exis = element.producto.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)
            element.id_almacen = datos.value.id_almacen_origen
            if (exis) {
              console.log('exis', exis.existencia)
              element.existencia = Number(exis.existencia)
            } else {
              element.existencia = 0
            }
          }
        } else {
          var exis = null
          exis = element.producto.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)
          element.id_almacen = datos.value.id_almacen_origen
          if (exis) {
            console.log('exis', exis.existencia)
            element.existencia = Number(exis.existencia)
          } else {
            element.existencia = 0
          }
        }
      })
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          TransladoServices.TransladoActualizar({ ...datos.value })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                  ...error,
                  response: error?.response?.data,
                },
                funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      store,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
      },
      eliminarProdcuto,
      funciones,
      filtrarAlmacenCosto,
      cambiarAlmacen,
      changeProductoTransformar,
      changeTipoTransferencia,
      changeTipoTransferenciaItem,
      cambiarAlmacenProducto,
      cargarSolcitud,
      route,
      acciones,
      validarAcciones
    }
  },
}
</script>